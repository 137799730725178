<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="initialize" :queryParam="queryParam">
            <el-table ref="shopListTable" slot="elList" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row>
                <el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams"
                                 :key="index"
                                 :prop="col.FieldName"
                                 :label="col.DisplayName"
                                 :render-header="bindFilter(queryParam,col)"
                                 :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                 :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                 v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<4))">
                    <template slot-scope="scope">
                        <el-button v-if="col.FieldName==='ShopNo'" type="text" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button>
                        <span v-else>  {{ scope.row[col.FieldName] }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.initialize();//加载表格数据
        },
        data() {
            return {
                config: {
                    isDetailDisplay: false,
                },
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    params: {}
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
            }
        },
        props: {
            selectCondition: {

            }
        },
        methods: {
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            initialize: function () {
                if (this.selectCondition) {
                    this.Utils.extend(this.queryParam.params, this.selectCondition);
                }
                //this.queryParam.params.OwnerStatus = 1;
                var _this = this;
                this.$ajax.query("omsapi/customershop/getcustomershopinfos", "post", _this.queryParam, (result) => {
                    _this.dataSource = result;
                });
            },
        },

    }
</script>
